import React                                   from 'react';
import { Box, Flex, List, ListItem, ListIcon } from '@chakra-ui/react';
import { CheckIcon }                           from '@chakra-ui/icons';
import { graphql }                             from 'gatsby';
import { FaRulerCombined } from "react-icons/fa";
import { MdOutlineBedroomParent } from "react-icons/md";
import { FaCalendarCheck } from "react-icons/fa";

import SEO           from '@interness/web-core/src/components/modules/SEO/SEO';
import Wrapper       from '@interness/web-core/src/components/structure/Wrapper/Wrapper';
import Spacer        from '@interness/web-core/src/components/structure/Spacer/Spacer';
import Heading       from '@interness/web-core/src/components/text/Heading/Heading';
import NavExplorer   from '@interness/theme-sonora/src/components/NavExplorer';
import { findMedia } from '@interness/web-core/src/components/_helpers';
import ContactForm   from '@interness/web-core/src/components/modules/ContactForm/ContactForm';

const Sonderaktion = ({ data }) => {
  const nav = [
    {
      title: 'Nischen',
      image: findMedia(data.navExplorer.media, 'nische_1'),
      linkTo: '/nischen'
    },
    {
      title: 'Schrägen',
      image: findMedia(data.navExplorer.media, 'schraege_2'),
      linkTo: '/schragen'
    },
    {
      title: 'Garderoben',
      image: findMedia(data.navExplorer.media, 'garderobe_1'),
      linkTo: '/garderoben'
    },
    {
      title: 'Schlafzimmer',
      image: findMedia(data.navExplorer.media, 'schlafzimmer_1'),
      linkTo: '/schlafzimmer'
    },
    {
      title: 'Schiebetüren',
      image: findMedia(data.navExplorer.media, 'schiebetur_1'),
      linkTo: '/schiebeturen'
    },
    {
      title: 'Kleiderzimmer',
      image: findMedia(data.navExplorer.media, 'kleiderzimmer_1'),
      linkTo: '/kleiderzimmer'
    },
  ]
  return (
    <>
      <SEO title="Sonderaktion"/>
      <Wrapper>
        <Spacer/>
        <Heading icon={<FaRulerCombined />} subtitle='Für alle "Schwierigen Ecken": Die perfekte Schranklösung für ALLE Räumlichkeiten'>Einbauschränke
          nach Maß</Heading>
        <Spacer/>
        <Flex alignItems="flex-start" justifyContent={'space-between'} wrap="wrap">
          <Box w={['100%', '100%', '100%', '48%']} boxShadow="2xl" borderRadius="md" p={6} mb={12}>
            <h2>Was genau machen wir?</h2>
            <List listStylePosition="outside">
              <ListItem>
                <ListIcon as={CheckIcon} color="red.500"/>
                Verfügbaren Raum optimal mit einem maßgefertigten Schrankeinbau nutzen. Wir sind der richtige
                Ansprechpartner für Sie.</ListItem>
              <ListItem>
                <ListIcon as={CheckIcon} color="red.500"/>
                Individuelle Lösungen nach Maß müssen nicht teuer sein. Lassen Sie sich bei einem kostenlosen
                Planungstermin überzeugen.
              </ListItem>
              <ListItem><ListIcon as={CheckIcon} color="red.500"/>Wir planen für Sie vor Ort. Individuell, günstig und
                schnell.</ListItem>
              <ListItem><ListIcon as={CheckIcon} color="red.500"/>Bei unserer kostenlosen Beratung und Planung erfahren
                Sie von uns den Preis direkt.</ListItem>
            </List>
          </Box>
          <Box w={['100%', '100%', '100%', '48%']} boxShadow="2xl" borderRadius="md" p={6} mb={12}>
            <h2>Ihre Vorteile:</h2>
            <List>
              <ListItem><ListIcon as={CheckIcon} color="green.500"/>Perfekte Handwerksqualität aus Deutschland. Seit
                mehr als 15 Jahren.</ListItem>
              <ListItem><ListIcon as={CheckIcon} color="green.500"/>Erstklassige Beratung, perfekte Planung und
                kurzfristige Umsetzung: Von der Idee bis zur fertigen Montage.</ListItem>
              <ListItem><ListIcon as={CheckIcon} color="green.500"/>Lebenslange Garantie.</ListItem>
            </List>
          </Box>
        </Flex>
        <Box boxShadow="2xl" borderRadius="md" p={6} mb={12}>
          <h2>Seit über 15 Jahren aus Wuppertal:</h2>
          Egal ob Nische, Dachschräge, Schlafzimmer oder Extrahoch. <br/><br/>
          Wir bieten Ihnen garantiert eine kostengünstige, schöne Planung und Realisierung eines Schrankes nach Maß
          an. <br/><br/>
          Der Perfekte Schrank für Ihren Individuellen Raum ohne Extrakosten! <br/><br/>
        </Box>
        <Spacer/>
        <Heading icon={<MdOutlineBedroomParent />} tag="h2">Für alle Räumlichkeiten</Heading>
        <Wrapper width={1200}>
          <NavExplorer nav={nav}/>
        </Wrapper>
        <Spacer/>
        <Heading icon={<FaCalendarCheck />} tag="h2">Sonderaktion: Terminanfrage senden und 3x Zubehör geschenkt bekommen!</Heading>
        <p>Beispielsweise erhalten Sie einen Schubkastensatz mit 3 Schubkästen + einen Kleiderlift + 3er Set
          Einlegeböden GESCHENKT!</p>
        <Spacer/>
        <ContactForm/>
      </Wrapper>
    </>
  )
};

export default Sonderaktion

export const query = graphql`
    query SonderkationQuery {
        navExplorer: directusMediaCollection(name: {eq: "keep-exploring-nav"}) {
            media {
                file {
                    localFile {
                        name
                        publicURL
                        childImageSharp {
                            fluid {
                                ...GatsbyImageSharpFluid
                            }
                        }
                    }
                }
            }
        }
    }
`;